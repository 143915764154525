import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import LandingPage from './components/LandingPage';
import Auth from './components/Auth';
import FlashcardCreationSelector from './components/FlashcardCreationSelector';
import AppLayout from './components/AppLayout';
import Dashboard from './components/Dashboard';
import StudyMode from './components/StudyMode';
import Quiz from './components/Quiz';

function App() {
  const [user, setUser] = useState(null);
  const [flashCards, setFlashCards] = useState([]);
  const [categories, setCategories] = useState([]); 
  const [activeTab, setActiveTab] = useState('dashboard');
  const [userActivity, setUserActivity] = useState({ flashcardAnswers: [], loginAndStudy: [] });

  const fetchUserActivity = useCallback(async (userId) => {
    const answersQuery = query(collection(db, 'flashcardAnswers'), where('userId', '==', userId));
    const answersSnapshot = await getDocs(answersQuery);
    const answers = answersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const loginAndStudyQuery = query(collection(db, 'loginAndStudy'), where('userId', '==', userId));
    const loginAndStudySnapshot = await getDocs(loginAndStudyQuery);
    const loginAndStudy = loginAndStudySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    setUserActivity({
      flashcardAnswers: answers,
      loginAndStudy: loginAndStudy
    });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        fetchFlashCards(user.uid);
        fetchUserActivity(user.uid);
        recordLoginActivity(user.uid);
      } else {
        setUser(null);
        setFlashCards([]);
        setCategories([]);
        setUserActivity({ flashcardAnswers: [], loginAndStudy: [] });
      }
    });
    return () => unsubscribe();
  }, [fetchUserActivity]);

  const fetchFlashCards = async (userId) => {
    const q = query(collection(db, 'flashcards'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const cards = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setFlashCards(cards);

    const uniqueCategories = [...new Set(cards.map(card => card.category))];
    setCategories(uniqueCategories);
  };

  const recordLoginActivity = async (userId) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const loginQuery = query(
      collection(db, 'loginAndStudy'),
      where('userId', '==', userId),
      where('date', '>=', today)
    );
    const loginSnapshot = await getDocs(loginQuery);
    
    if (loginSnapshot.empty) {
      await addDoc(collection(db, 'loginAndStudy'), {
        userId: userId,
        date: serverTimestamp(),
        studied: false
      });
    }
  };

  const recordStudyActivity = async () => {
    if (user) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const studyQuery = query(
        collection(db, 'loginAndStudy'),
        where('userId', '==', user.uid),
        where('date', '>=', today)
      );
      const studySnapshot = await getDocs(studyQuery);
      
      if (!studySnapshot.empty) {
        const docRef = doc(db, 'loginAndStudy', studySnapshot.docs[0].id);
        await updateDoc(docRef, { studied: true });
      } else {
        await addDoc(collection(db, 'loginAndStudy'), {
          userId: user.uid,
          date: serverTimestamp(),
          studied: true
        });
      }
      
      fetchUserActivity(user.uid);
    }
  };

  const handleManualCreate = async (flashcard) => {
    if (user) {
      await addDoc(collection(db, 'flashcards'), {
        userId: user.uid,
        question: flashcard.question,
        answer: flashcard.answer,
        category: flashcard.topic,
        createdAt: new Date()
      });
      fetchFlashCards(user.uid);
    }
  };

  const handleAIGenerate = async (generatedFlashcards) => {
    if (user) {
      for (const flashcard of generatedFlashcards) {
        await addDoc(collection(db, 'flashcards'), {
          userId: user.uid,
          question: flashcard.question,
          answer: flashcard.answer,
          category: flashcard.category,
          createdAt: new Date()
        });
      }
      fetchFlashCards(user.uid);
    }
  };

  const handleUpdateFlashcard = async (updatedCard) => {
    if (user) {
      const cardRef = doc(db, 'flashcards', updatedCard.id);
      await updateDoc(cardRef, {
        question: updatedCard.question,
        answer: updatedCard.answer,
        updatedAt: new Date()
      });
      fetchFlashCards(user.uid);
    }
  };

  const handleDeleteFlashcard = async (cardId) => {
    if (user) {
      await deleteDoc(doc(db, 'flashcards', cardId));
      fetchFlashCards(user.uid);
    }
  };

  const handleAnswerSubmit = async (answerData) => {
    if (user) {
      await addDoc(collection(db, 'flashcardAnswers'), {
        ...answerData,
        userId: user.uid
      });
      setUserActivity(prevActivity => ({
        ...prevActivity,
        flashcardAnswers: [...prevActivity.flashcardAnswers, answerData]
      }));
    }
  };

  const handleAddFlashcards = async (newFlashcards) => {
    if (user) {
      for (const flashcard of newFlashcards) {
        try {
          await addDoc(collection(db, 'flashcards'), {
            userId: user.uid,
            question: flashcard.question,
            answer: flashcard.answer,
            category: flashcard.category,
            createdAt: new Date()
          });
        } catch (error) {
          console.error("Error adding flashcard:", error);
        }
      }
      fetchFlashCards(user.uid);
    }
  };

  const handleStartStudying = () => {
    setActiveTab('study');
    recordStudyActivity();
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return (
          <Dashboard 
            flashCards={flashCards} 
            categories={categories} 
            setActiveTab={handleStartStudying}  // Change this line
            userActivity={userActivity}
          />
        );
      case 'manual':
      case 'ai':
      case 'manage':
        return (
          <FlashcardCreationSelector
            onManualCreate={handleManualCreate}
            onAIGenerate={handleAIGenerate}
            flashCards={flashCards}
            onUpdateFlashcard={handleUpdateFlashcard}
            onDeleteFlashcard={handleDeleteFlashcard}
            activeTab={activeTab}
          />
        );
      case 'study':
        return (
          <StudyMode 
            flashCards={flashCards} 
            categories={categories} 
            onAnswerSubmit={handleAnswerSubmit}
          />
        );
      case 'quiz': 
        return (
          <Quiz 
            categories={categories}
            flashCards={flashCards}
            onAddFlashcards={handleAddFlashcards}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-blue-300 via-purple-300 to-blue-200">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Auth />} />
          <Route
            path="/app/*"
            element={
              user ? (
                <AppLayout activeTab={activeTab} setActiveTab={setActiveTab} onLogout={() => auth.signOut()}>
                  {renderContent()}
                </AppLayout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
