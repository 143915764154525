import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGw1z3OE97S_edqNhCWM1n16Tzmi0twY4",
  authDomain: "flashcard-36950.firebaseapp.com",
  projectId: "flashcard-36950",
  storageBucket: "flashcard-36950.appspot.com",
  messagingSenderId: "203566362242",
  appId: "1:203566362242:web:d6170195554d86d1b70e9b",
  measurementId: "G-QKGNQM6MQT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);