import React from 'react';
import { motion } from 'framer-motion';

const QuizLoadingAnimation = () => {
  const circleVariants = {
    start: { scale: 0.8, opacity: 0.3 },
    end: { scale: 1, opacity: 1 }
  };

  const lineVariants = {
    start: { pathLength: 0, opacity: 0 },
    end: { pathLength: 1, opacity: 1 }
  };

  return (
    <div className="flex justify-center items-center h-40">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        viewBox="0 0 100 100"
      >
        <motion.circle
          cx="50"
          cy="50"
          r="45"
          stroke="#3b82f6"
          strokeWidth="2"
          fill="none"
          initial="start"
          animate="end"
          variants={circleVariants}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut'
          }}
        />
        <motion.path
          d="M30,50 L70,50 M50,30 L50,70"
          stroke="#3b82f6"
          strokeWidth="2"
          strokeLinecap="round"
          fill="none"
          initial="start"
          animate="end"
          variants={lineVariants}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut'
          }}
        />
        <motion.path
          d="M30,50 Q50,30 70,50 Q50,70 30,50"
          stroke="#3b82f6"
          strokeWidth="2"
          strokeLinecap="round"
          fill="none"
          initial="start"
          animate="end"
          variants={lineVariants}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
            delay: 0.75
          }}
        />
      </motion.svg>
    </div>
  );
};

export default QuizLoadingAnimation;