import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, RotateCcw } from 'lucide-react';
import { Button } from './ui/button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from './ui/select';

const StudyMode = ({ flashCards, categories, onAnswerSubmit }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [studyCards, setStudyCards] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    const filteredCards = selectedCategory === 'All'
      ? flashCards
      : flashCards.filter(card => card.category === selectedCategory);

    // Sort cards to prioritize "Still Learning" cards
    const sortedCards = filteredCards.sort((a, b) => {
      if (a.lastResult === 'Still Learning' && b.lastResult !== 'Still Learning') return -1;
      if (b.lastResult === 'Still Learning' && a.lastResult !== 'Still Learning') return 1;
      return 0;
    });

    // Randomize the order of cards
    const shuffledCards = sortedCards
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    setStudyCards(shuffledCards);
    setCurrentCardIndex(0);
    setShowAnswer(false);
  }, [selectedCategory, flashCards]);

  const handleNext = (result) => {
    const currentCard = studyCards[currentCardIndex];
    if (result) {
      onAnswerSubmit({
        cardId: currentCard.id,
        question: currentCard.question,
        answer: currentCard.answer,
        category: currentCard.category,
        result: result,
        date: new Date().toISOString()
      });
    }
    if (currentCardIndex < studyCards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowAnswer(false);
    }
  };

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setShowAnswer(false);
    }
  };

  const handleRestart = () => {
    // Reshuffle cards when restarting
    setStudyCards(cards => {
      return cards
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    });
    setCurrentCardIndex(0);
    setShowAnswer(false);
  };

  if (studyCards.length === 0) {
    return <div className="text-center text-2xl mt-20">No flashcards available for this category.</div>;
  }

  const currentCard = studyCards[currentCardIndex];
  const progress = ((currentCardIndex + 1) / studyCards.length) * 100;

  return (
    <div className="max-w-2xl mx-auto mt-10 px-4">
      <div className="mb-8">
        <Select value={selectedCategory} onValueChange={setSelectedCategory}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="All">All Categories</SelectItem>
            {categories.map(category => (
              <SelectItem key={category} value={category}>{category}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="relative h-4 bg-gray-200 rounded-full overflow-hidden mb-8">
        <motion.div
          className="h-full bg-green-500"
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.5 }}
        />
      </div>

      <div className="relative h-80 mb-8">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentCardIndex + (showAnswer ? '-answer' : '-question')}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="absolute inset-0 bg-white rounded-xl shadow-lg p-8 flex flex-col justify-between"
          >
            <div className="flex flex-col justify-center flex-grow">
              <h2 className="text-2xl font-bold mb-4 text-center">
                {showAnswer ? 'Answer:' : 'Question:'}
              </h2>
              <p className="text-xl text-center px-4">
                {showAnswer ? currentCard.answer : currentCard.question}
              </p>
            </div>
            {!showAnswer && (
              <div className="flex justify-center mt-4">
                <Button onClick={() => setShowAnswer(true)} className="w-full">
                  Show Answer
                </Button>
              </div>
            )}
            {showAnswer && (
              <div className="flex justify-between mt-4">
                <Button onClick={() => handleNext('Still Learning')} className="bg-red-100 text-red-700 hover:bg-red-200 flex-1 mr-2">
                  Still Learning
                </Button>
                <Button onClick={() => handleNext('I Know This')} className="bg-green-100 text-green-700 hover:bg-green-200 flex-1 ml-2">
                  I Know This
                </Button>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>

      <div className="flex justify-between items-center mt-8">
        <Button onClick={handlePrevious} disabled={currentCardIndex === 0}>
          <ChevronLeft className="mr-2" /> Previous
        </Button>
        <Button onClick={handleRestart} variant="outline">
          <RotateCcw className="mr-2" /> Restart
        </Button>
        <Button onClick={() => handleNext()} disabled={currentCardIndex === studyCards.length - 1}>
          Next <ChevronRight className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

export default StudyMode;