import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Pencil, Trash2, X, Check} from 'lucide-react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';

const ManageFlashcards = ({ flashCards = [], onUpdateFlashcard, onDeleteFlashcard }) => {
  const [editingCard, setEditingCard] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState('All Topics');

  const topics = useMemo(() => {
    const allTopics = ['All Topics', ...new Set(flashCards.map(card => card.category))];
    return allTopics.sort();
  }, [flashCards]);

  const filteredFlashcards = useMemo(() => {
    if (selectedTopic === 'All Topics') {
      return flashCards;
    }
    return flashCards.filter(card => card.category === selectedTopic);
  }, [flashCards, selectedTopic]);

  const handleEdit = (card) => {
    setEditingCard({ ...card });
  };

  const handleSave = () => {
    onUpdateFlashcard(editingCard);
    setEditingCard(null);
  };

  const handleCancel = () => {
    setEditingCard(null);
  };

  const handleDelete = (cardId) => {
    onDeleteFlashcard(cardId);
  };

  return (
    <div className="space-y-6">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Select value={selectedTopic} onValueChange={setSelectedTopic}>
          <SelectTrigger className="w-full md:w-[280px] bg-white">
            <SelectValue placeholder="Select a topic" />
          </SelectTrigger>
          <SelectContent>
            {topics.map((topic) => (
              <SelectItem key={topic} value={topic}>
                {topic}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </motion.div>

      <AnimatePresence>
        {filteredFlashcards.map((card) => (
          <motion.div
            key={card.id}
            layout
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="bg-white rounded-lg shadow-md p-4"
          >
            {editingCard && editingCard.id === card.id ? (
              <div className="space-y-2">
                <Input
                  value={editingCard.question}
                  onChange={(e) => setEditingCard({ ...editingCard, question: e.target.value })}
                  placeholder="Question"
                />
                <Input
                  value={editingCard.answer}
                  onChange={(e) => setEditingCard({ ...editingCard, answer: e.target.value })}
                  placeholder="Answer"
                />
                <div className="flex justify-end space-x-2">
                  <Button onClick={handleSave} className="bg-emerald-100 text-emerald-700 hover:bg-emerald-200">
                    <Check className="w-4 h-4 mr-2" />
                    Save
                  </Button>
                  <Button onClick={handleCancel} className="bg-gray-100 text-gray-700 hover:bg-gray-200">
                    <X className="w-4 h-4 mr-2" />
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <h3 className="font-semibold text-lg mb-2">{card.question}</h3>
                <p className="text-gray-600 mb-2">{card.answer}</p>
                <p className="text-sm text-gray-500 mb-4">Topic: {card.category}</p>
                <div className="flex justify-end space-x-2">
                  <Button 
                    onClick={() => handleEdit(card)} 
                    className="bg-sky-100 text-sky-700 hover:bg-sky-200 transition-colors duration-200"
                  >
                    <Pencil className="w-4 h-4 mr-2" />
                    Edit
                  </Button>
                  <Button 
                    onClick={() => handleDelete(card.id)} 
                    className="bg-rose-100 text-rose-700 hover:bg-rose-200 transition-colors duration-200"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Delete
                  </Button>
                </div>
              </div>
            )}
          </motion.div>
        ))}
      </AnimatePresence>

      {filteredFlashcards.length === 0 && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center text-gray-500 mt-8"
        >
          No flashcards available for the selected topic. Create some flashcards to get started!
        </motion.p>
      )}
    </div>
  );
};

export default ManageFlashcards;