import React, { useMemo, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Brain, Zap, TrendingUp, Clock } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';

const Dashboard = ({ flashCards, categories, setActiveTab, userActivity = {}, updateFlashcardAnswer }) => {
  const [showAnswers, setShowAnswers] = useState({});

  useEffect(() => {
    console.log('userActivity updated:', userActivity);
  }, [userActivity]);

  const stats = useMemo(() => {
    const total = flashCards.length;
    const categoryCounts = categories.map(category => ({
      name: category,
      value: flashCards.filter(card => card.category === category).length
    }));
    const recentlyAdded = flashCards.slice(-5).reverse();

    // Calculate Mastered percentage
    let masteredPercentage = 0;
    if (userActivity && Array.isArray(userActivity.flashcardAnswers)) {
      const last30Days = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      const recentAnswers = userActivity.flashcardAnswers.filter(answer => new Date(answer.date) >= last30Days);
      const totalAnswered = recentAnswers.length;
      const knownAnswers = recentAnswers.filter(answer => answer.result === 'I Know This').length;
      masteredPercentage = totalAnswered > 0 ? Math.round((knownAnswers / totalAnswered) * 100) : 0;
    }

    // Calculate Study Streak
    let streak = 0;
    if (userActivity && Array.isArray(userActivity.loginAndStudy)) {
      const sortedActivity = userActivity.loginAndStudy
        .filter(activity => activity && activity.date)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      for (const activity of sortedActivity) {
        const activityDate = new Date(activity.date);
        activityDate.setHours(0, 0, 0, 0);

        if (currentDate.getTime() === activityDate.getTime() && activity.studied) {
          streak++;
          currentDate.setDate(currentDate.getDate() - 1);
        } else if (currentDate.getTime() !== activityDate.getTime()) {
          break;
        }
      }
    }

    return { total, categoryCounts, recentlyAdded, masteredPercentage, streak };
  }, [flashCards, categories, userActivity]);

  const toggleAnswer = (cardId) => {
    setShowAnswers(prev => ({
      ...prev,
      [cardId]: !prev[cardId]
    }));
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

  const handleStartStudying = () => {
    setActiveTab('study');
  };

  return (
    <div className="space-y-6">
      <motion.h1 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-3xl md:text-4xl font-bold text-gray-800"
      >
        Welcome to Your FlashMaster Dashboard
      </motion.h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
        <StatCard icon={Brain} title="Total Flashcards" value={stats.total} color="bg-blue-500" />
        <StatCard icon={Zap} title="Categories" value={categories.length} color="bg-green-500" />
        <StatCard icon={TrendingUp} title="Mastered" value={`${stats.masteredPercentage}%`} color="bg-yellow-500" />
        <StatCard icon={Clock} title="Study Streak" value={`${stats.streak} days`} color="bg-purple-500" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Flashcards by Category</CardTitle>
          </CardHeader>
          <CardContent className="flex justify-center items-center h-[300px] md:h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={stats.categoryCounts}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {stats.categoryCounts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Recently Added Flashcards</CardTitle>
          </CardHeader>
          <CardContent>
            <ul className="space-y-4">
              {stats.recentlyAdded.map((card, index) => (
                <motion.li
                  key={card.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-gray-100 p-4 rounded-md"
                >
                  <p className="font-semibold mb-1">{card.question}</p>
                  <p className="text-sm text-gray-600 mb-2">{card.category}</p>
                  <Button 
                    onClick={() => toggleAnswer(card.id)} 
                    className="w-full justify-center text-sm"
                    variant="outline"
                  >
                    {showAnswers[card.id] ? 'Hide Answer' : 'Show Answer'}
                  </Button>
                  {showAnswers[card.id] && (
                    <motion.p
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="mt-2 p-2 bg-white rounded text-sm"
                    >
                      {card.answer}
                    </motion.p>
                  )}
                </motion.li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </div>

      <div className="flex justify-center">
        <Button
          size="lg"
          onClick={handleStartStudying}
          className="w-full sm:w-auto bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transform transition duration-200 ease-in-out hover:scale-105"
        >
          Start Studying
        </Button>
      </div>
    </div>
  );
};

const StatCard = ({ icon: Icon, title, value, color }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`${color} rounded-xl shadow-lg p-4 md:p-6 text-white`}
  >
    <Icon className="w-6 h-6 md:w-8 md:h-8 mb-2 md:mb-4" />
    <h2 className="text-lg md:text-xl font-semibold mb-1 md:mb-2">{title}</h2>
    <p className="text-2xl md:text-3xl font-bold">{value}</p>
  </motion.div>
);

export default Dashboard;