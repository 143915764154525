//Quiz.js
import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Label } from "./ui/label";
import { Slider } from "./ui/slider";
import { Checkbox } from "./ui/checkbox";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle2, XCircle, Brain } from "lucide-react";
import QuizLoadingAnimation from './QuizLoadingAnimation';

const Quiz = ({ categories, flashCards, onAddFlashcards }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [questionCount, setQuestionCount] = useState(5);
  const [difficulty, setDifficulty] = useState('');
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedForFlashcards, setSelectedForFlashcards] = useState([]);
  const [flashcardAddedMessage, setFlashcardAddedMessage] = useState('');

  const generateQuiz = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const categoryFlashcards = flashCards.filter(card => card.category === selectedCategory);
      const response = await fetch('https://us-central1-flashcard-36950.cloudfunctions.net/generateQuizV2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          category: selectedCategory,
          flashcards: categoryFlashcards,
          questionCount: parseInt(questionCount),
          difficulty
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'An error occurred while generating the quiz');
      }

      setQuizQuestions(result.questions);
      setCurrentQuestion(0);
      setScore(0);
      setShowResults(false);
    } catch (error) {
      console.error("Error generating quiz:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswer = (selectedAnswer) => {
    const isCorrect = selectedAnswer === quizQuestions[currentQuestion].correctAnswer;
    setUserAnswers([...userAnswers, { question: currentQuestion, answer: selectedAnswer, isCorrect }]);
    
    if (isCorrect) {
      setScore(score + 1);
    }

    if (currentQuestion < quizQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  const resetQuiz = () => {
    setQuizQuestions([]);
    setCurrentQuestion(0);
    setUserAnswers([]);
    setScore(0);
    setShowResults(false);
    setSelectedCategory('');
    setQuestionCount(5);
    setDifficulty('');
    setSelectedForFlashcards([]);
  };

  const progressPercentage = quizQuestions.length > 0 
    ? ((currentQuestion + 1) / quizQuestions.length) * 100 
    : 0;

  const difficultyColors = {
    easy: 'bg-green-500',
    medium: 'bg-yellow-500',
    hard: 'bg-red-500'
  };

  const handleFlashcardSelection = (index) => {
    setSelectedForFlashcards(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  const addSelectedAsFlashcards = () => {
    const newFlashcards = selectedForFlashcards.map(index => ({
      category: selectedCategory,
      question: quizQuestions[index].question,
      answer: quizQuestions[index].correctAnswer
    }));

    if (typeof onAddFlashcards === 'function') {
      onAddFlashcards(newFlashcards);
      setFlashcardAddedMessage('Flashcards added successfully!');
    } else {
      console.warn('onAddFlashcards is not a function. Flashcards could not be added.');
      setFlashcardAddedMessage('Flashcards could not be added. Please try again later.');
    }

    setSelectedForFlashcards([]);

    setTimeout(() => setFlashcardAddedMessage(''), 3000);
  };

  // ResultSummary component added to display after the quiz ends
  const ResultSummary = () => (
    <div className="space-y-6">
      <h3 className="text-3xl font-bold mb-4">Quiz Results</h3>
      <div className="text-6xl font-bold text-blue-600 mb-4">{score} / {quizQuestions.length}</div>
      <p className="text-xl text-gray-600 mb-6">
        {score === quizQuestions.length ? "Perfect score! You're a genius!" :
         score >= quizQuestions.length * 0.8 ? "Great job! You're almost there!" :
         score >= quizQuestions.length * 0.6 ? "Good effort! Keep practicing!" :
         "Keep learning! You've got this!"}
      </p>
      <div className="space-y-4">
        {quizQuestions.map((question, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-4">
            <div className="flex items-start">
              <Checkbox 
                id={`flashcard-${index}`}
                checked={selectedForFlashcards.includes(index)}
                onCheckedChange={() => handleFlashcardSelection(index)}
                className="mr-2 mt-1"
              />
              {userAnswers[index].isCorrect ? (
                <CheckCircle2 className="text-green-500 mr-2 mt-1 flex-shrink-0" />
              ) : (
                <XCircle className="text-red-500 mr-2 mt-1 flex-shrink-0" />
              )}
              <div className="flex-grow">
                <p className="font-semibold">{question.question}</p>
                <p className="text-sm text-gray-600">Your answer: {userAnswers[index].answer}</p>
                {!userAnswers[index].isCorrect && (
                  <p className="text-sm text-green-600">Correct answer: {question.correctAnswer}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="space-y-4">
        <Button 
          onClick={addSelectedAsFlashcards} 
          disabled={selectedForFlashcards.length === 0}
          className="w-full text-lg py-4 px-8 bg-green-500 hover:bg-green-600"
        >
          Add Selected to Flashcards
        </Button>
        {flashcardAddedMessage && (
          <p className="text-sm text-center text-green-600">{flashcardAddedMessage}</p>
        )}
        <Button onClick={resetQuiz} className="w-full text-lg py-4 px-8">
          Start New Quiz
        </Button>
      </div>
    </div>
  );

  return (
    <Card className="w-full max-w-3xl mx-auto mt-8 shadow-lg">
      <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
        <CardTitle className="text-3xl font-bold">Quiz Challenge</CardTitle>
        <CardDescription className="text-lg text-gray-100">Test your knowledge with our interactive quiz!</CardDescription>
      </CardHeader>
      <CardContent className="p-6">
        {quizQuestions.length === 0 ? (
          <div className="space-y-6">
            {/* Quiz setup */}
            <div>
              <Label htmlFor="category" className="text-lg font-semibold mb-2 block">Category</Label>
              <Select onValueChange={setSelectedCategory} value={selectedCategory}>
                <SelectTrigger id="category" className="w-full">
                  <SelectValue placeholder="Select a category" />
                </SelectTrigger>
                <SelectContent>
                  {categories.map((category) => (
                    <SelectItem key={category} value={category}>
                      {category}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {selectedCategory && (
                <p className="mt-2 text-sm text-gray-600">Selected: {selectedCategory}</p>
              )}
            </div>
            <div>
              <Label htmlFor="questionCount" className="text-lg font-semibold mb-2 block">Number of Questions: {questionCount}</Label>
              <Slider
                id="questionCount"
                min={1}
                max={10}
                step={1}
                value={[questionCount]}
                onValueChange={(value) => setQuestionCount(value[0])}
                className="my-4"
              />
            </div>
            <div>
              <Label htmlFor="difficulty" className="text-lg font-semibold mb-2 block">Difficulty</Label>
              <Select onValueChange={setDifficulty} value={difficulty}>
                <SelectTrigger id="difficulty" className="w-full">
                  <SelectValue placeholder="Select difficulty" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="easy">Easy</SelectItem>
                  <SelectItem value="medium">Medium</SelectItem>
                  <SelectItem value="hard">Hard</SelectItem>
                </SelectContent>
              </Select>
              {difficulty && (
                <p className="mt-2 text-sm text-gray-600">Selected: {difficulty}</p>
              )}
            </div>
            {isLoading ? (
              <div className="text-center">
                <QuizLoadingAnimation />
                <p className="text-lg text-gray-600 mt-4">Crafting your personalized quiz...</p>
              </div>
            ) : (
              <Button 
                onClick={generateQuiz} 
                disabled={!selectedCategory || !difficulty}
                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 disabled:bg-gray-300 flex items-center justify-center"
              >
                <Brain className="mr-2 h-5 w-5" />
                Generate Quiz
              </Button>
            )}
            {error && <p className="text-red-500 text-lg">{error}</p>}
          </div>
        ) : showResults ? (
          <ResultSummary />
        ) : (
          <div>
            {/* Quiz questions and progress */}
            <div className="mb-6">
              <h3 className="text-2xl font-semibold mb-2">Question {currentQuestion + 1} of {quizQuestions.length}</h3>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progressPercentage}%` }}></div>
              </div>
            </div>
            <AnimatePresence mode="wait">
              <motion.div
                key={currentQuestion}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
              >
                <div className="flex items-center mb-4">
                  <p className="text-xl font-medium flex-grow">{quizQuestions[currentQuestion].question}</p>
                  <span className={`ml-2 px-2 py-1 rounded text-white text-sm ${difficultyColors[quizQuestions[currentQuestion].difficulty]}`}>
                    {quizQuestions[currentQuestion].difficulty}
                  </span>
                </div>
                <p className="text-sm text-gray-500 mb-4">
                  {quizQuestions[currentQuestion].isFromFlashcard ? "From your flashcards" : "New question"}
                </p>
                <RadioGroup onValueChange={handleAnswer} className="space-y-4">
                  {quizQuestions[currentQuestion].options.map((option, index) => (
                    <div key={index} className="flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                      <RadioGroupItem 
                        value={option} 
                        id={`option-${index}`} 
                        className="w-5 h-5 border-2 border-blue-500 text-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      />
                      <Label htmlFor={`option-${index}`} className="text-lg cursor-pointer flex-grow">{option}</Label>
                    </div>
                  ))}
                </RadioGroup>
              </motion.div>
            </AnimatePresence>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Quiz;