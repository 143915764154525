import * as React from "react"
import PropTypes from 'prop-types'
import { ChevronDown } from 'lucide-react'
import { cn } from "../../lib/utils"

const Select = React.forwardRef(({ className, children, value, onValueChange, ...props }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const selectRef = React.useRef(null)

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div ref={selectRef} className={cn("relative", className)} {...props}>
      {React.Children.map(children, child => 
        React.cloneElement(child, { isOpen, setIsOpen, value, onValueChange })
      )}
    </div>
  )
})
Select.displayName = "Select"

const SelectTrigger = React.forwardRef(({ className, children, isOpen, setIsOpen, ...props }, ref) => (
  <button
    ref={ref}
    className={cn(
      "flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    onClick={() => setIsOpen(!isOpen)}
    {...props}
  >
    {children}
    <ChevronDown className={cn("h-4 w-4 opacity-50 transition-transform duration-200", {
      "transform rotate-180": isOpen
    })} />
  </button>
))
SelectTrigger.displayName = "SelectTrigger"

const SelectValue = React.forwardRef(({ className, placeholder, value, ...props }, ref) => (
  <span ref={ref} className={cn("block truncate", className)} {...props}>
    {value || placeholder}
  </span>
))
SelectValue.displayName = "SelectValue"

const SelectContent = React.forwardRef(({ className, children, isOpen, value, onValueChange, ...props }, ref) => {
  if (!isOpen) return null
  
  return (
    <div
      ref={ref}
      className={cn(
        "absolute z-50 w-full mt-1 overflow-hidden rounded-md border bg-white text-gray-900 shadow-lg animate-in fade-in-80",
        className
      )}
      {...props}
    >
      <div className="p-1">
        {React.Children.map(children, child => 
          React.cloneElement(child, { onValueChange, isSelected: child.props.value === value })
        )}
      </div>
    </div>
  )
})
SelectContent.displayName = "SelectContent"

const SelectItem = React.forwardRef(({ className, children, value, onValueChange, isSelected, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative flex cursor-pointer select-none items-center rounded-sm py-1.5 px-2 text-sm outline-none",
      isSelected ? "bg-blue-100 text-blue-900" : "hover:bg-gray-100",
      className
    )}
    onClick={() => onValueChange(value)}
    {...props}
  >
    {isSelected && (
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <span className="h-2 w-2 rounded-full bg-blue-500" />
      </span>
    )}
    <span className={cn("ml-8", { "font-medium": isSelected })}>{children}</span>
  </div>
))
SelectItem.displayName = "SelectItem"

SelectItem.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
}

export { Select, SelectTrigger, SelectValue, SelectContent, SelectItem }
