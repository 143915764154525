import React, { useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Sparkles, Plus } from 'lucide-react';
import './LoadingSpinner.css';
import ManageFlashcards from './ManageFlashcards';
import ExcelUploader from './ExcelUploader';

const FlashcardCreationSelector = ({ onManualCreate, onAIGenerate, flashCards, onUpdateFlashcard, onDeleteFlashcard, activeTab }) => {
  const [manualFlashcard, setManualFlashcard] = useState({ topic: '', question: '', answer: '' });
  const [aiGeneration, setAIGeneration] = useState({ subject: '', category: '' });
  const [generatedFlashcards, setGeneratedFlashcards] = useState([]);
  const [selectedFlashcards, setSelectedFlashcards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [excelUploadError, setExcelUploadError] = useState(null);

  const handleManualCreate = () => {
    onManualCreate(manualFlashcard);
    setManualFlashcard({ topic: '', question: '', answer: '' });
  };

  const generateFlashcards = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://us-central1-flashcard-36950.cloudfunctions.net/generateFlashcards', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic: aiGeneration.subject }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'An error occurred while generating flashcards');
      }

      setGeneratedFlashcards(result.flashcards);
      setSelectedFlashcards(result.flashcards.map(() => true));
    } catch (error) {
      console.error("Error generating flashcards:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFlashcardSelection = (index) => {
    const newSelectedFlashcards = [...selectedFlashcards];
    newSelectedFlashcards[index] = !newSelectedFlashcards[index];
    setSelectedFlashcards(newSelectedFlashcards);
  };

  const handleAddSelectedFlashcards = () => {
    const flashcardsToAdd = generatedFlashcards
      .filter((_, index) => selectedFlashcards[index])
      .map(flashcard => ({
        question: flashcard.question,
        answer: flashcard.answer,
        category: aiGeneration.category || aiGeneration.subject
      }));

    onAIGenerate(flashcardsToAdd);
    setGeneratedFlashcards([]);
    setSelectedFlashcards([]);
    setAIGeneration({ subject: '', category: '' });
  };

  const handleAddFlashcardsFromExcel = (flashcards) => {
    try {
      onAIGenerate(flashcards); // Reusing onAIGenerate to add flashcards from Excel
      setExcelUploadError(null);
    } catch (error) {
      console.error("Error adding flashcards from Excel:", error);
      setExcelUploadError("An error occurred while adding flashcards. Please try again.");
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'manual':
        return (
          <div className="space-y-6">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Create Flashcard</CardTitle>
                <CardDescription>Manually add a new flashcard to your collection.</CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <Input
                  placeholder="Topic"
                  value={manualFlashcard.topic}
                  onChange={(e) => setManualFlashcard({ ...manualFlashcard, topic: e.target.value })}
                />
                <Input
                  placeholder="Question"
                  value={manualFlashcard.question}
                  onChange={(e) => setManualFlashcard({ ...manualFlashcard, question: e.target.value })}
                />
                <Input
                  placeholder="Answer"
                  value={manualFlashcard.answer}
                  onChange={(e) => setManualFlashcard({ ...manualFlashcard, answer: e.target.value })}
                />
              </CardContent>
              <CardFooter>
                <Button
                  onClick={handleManualCreate}
                  className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transform transition duration-200 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                >
                  <Plus className="w-5 h-5 mr-2 inline" />
                  Add Flashcard
                </Button>
              </CardFooter>
            </Card>

            <Card className="w-full">
              <CardHeader>
                <CardTitle>Upload Flashcards from Excel</CardTitle>
                <CardDescription>Add multiple flashcards by uploading an Excel file.</CardDescription>
              </CardHeader>
              <CardContent>
                <ExcelUploader onAddFlashcards={handleAddFlashcardsFromExcel} />
                {excelUploadError && <p className="text-red-500 text-sm mt-2">{excelUploadError}</p>}
              </CardContent>
            </Card>
          </div>
        );

      case 'ai':
        return (
          <Card className="w-full">
            <CardHeader>
              <CardTitle>Generate Flashcards</CardTitle>
              <CardDescription>Use AI to generate flashcards on a subject.</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <Input
                placeholder="Enter subject for AI to generate flashcards"
                value={aiGeneration.subject}
                onChange={(e) => setAIGeneration({ ...aiGeneration, subject: e.target.value })}
                disabled={isLoading}
              />
              <Input
                placeholder="Enter a category"
                value={aiGeneration.category}
                onChange={(e) => setAIGeneration({ ...aiGeneration, category: e.target.value })}
                disabled={isLoading}
              />
              <Button
                onClick={generateFlashcards}
                disabled={isLoading || !aiGeneration.subject}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transform transition duration-200 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
              >
                {isLoading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Generating...
                  </span>
                ) : (
                  <>
                    <Sparkles className="w-5 h-5 mr-2 inline" />
                    Generate Flashcards
                  </>
                )}
              </Button>
              {error && <p className="text-red-500 text-sm">{error}</p>}

              {isLoading && (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p className="loading-text">AI is crafting your flashcards...</p>
                </div>
              )}

              {!isLoading && generatedFlashcards.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-2">Generated Flashcards:</h3>
                  {generatedFlashcards.map((flashcard, index) => (
                    <div key={index} className="flex items-start space-x-2 p-3 bg-gray-50 rounded-md mt-2">
                      <Checkbox
                        checked={selectedFlashcards[index]}
                        onCheckedChange={() => handleFlashcardSelection(index)}
                        className="mt-1"
                      />
                      <div className="flex-grow">
                        <p className="font-medium">Q: {flashcard.question}</p>
                        <p className="text-gray-600">A: {flashcard.answer}</p>
                      </div>
                    </div>
                  ))}
                  <Button
                    onClick={handleAddSelectedFlashcards}
                    className="mt-4 w-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transform transition duration-200 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    Add Selected Flashcards
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>
        );

      case 'manage':
        return (
          <Card className="w-full">
            <CardHeader>
              <CardTitle>Manage Flashcards</CardTitle>
              <CardDescription>Edit or delete your existing flashcards.</CardDescription>
            </CardHeader>
            <CardContent>
              <ManageFlashcards
                flashCards={flashCards}
                onUpdateFlashcard={onUpdateFlashcard}
                onDeleteFlashcard={onDeleteFlashcard}
              />
            </CardContent>
          </Card>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center w-full max-w-2xl mx-auto mt-8">
      {renderContent()}
    </div>
  );
};

export default FlashcardCreationSelector;
