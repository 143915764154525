import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="font-sans bg-gradient-to-b from-indigo-900 via-purple-900 to-pink-800 text-white">
      <Header />
      <HeroSection />
      <FlashcardSection />
      <QuizSection />
      <FeaturesSection />
      <HowItWorksSection />
      <Footer />
    </div>
  );
};

const Header = () => (
  <header className="absolute top-0 left-0 right-0 z-50">
    <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
      <div className="flex items-center">
        <motion.div
          initial={{ rotate: -180, opacity: 0 }}
          animate={{ rotate: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <svg className="w-10 h-10 mr-3" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" fill="white" />
            <path d="M30,30 L70,50 L30,70 Z" fill="#4C1D95" />
          </svg>
        </motion.div>
        <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">FlashMaster</span>
      </div>
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Link to="/login" className="px-6 py-2 rounded-full bg-white text-purple-700 font-semibold hover:bg-purple-100 transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1">
          Log In
        </Link>
      </motion.div>
    </nav>
  </header>
);

const HeroSection = () => (
  <section className="min-h-screen flex items-center justify-center text-center px-4">
    <div className="max-w-4xl mx-auto">
      <motion.h1 
        className="text-5xl md:text-7xl font-bold mb-6 leading-tight"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        Master Your Learning with <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">FlashMaster</span>
      </motion.h1>
      <motion.p 
        className="text-xl md:text-2xl mb-12 text-gray-300"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Unlock your potential with the ultimate tool to create, study, and manage flashcards with ease.
      </motion.p>
      <motion.div
        className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <Link to="/login" className="w-full sm:w-auto px-8 py-4 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 text-white font-bold text-lg hover:from-pink-600 hover:to-violet-600 transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1">
          Get Started
        </Link>
        <a href="https://www.youtube.com/embed/eWiECMj-w90" target="_blank" rel="noopener noreferrer" className="w-full sm:w-auto px-8 py-4 rounded-full bg-transparent border-2 border-white text-white font-bold text-lg hover:bg-white hover:text-purple-700 transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1">
          Learn More
        </a>
      </motion.div>
    </div>
  </section>
);

const FlashcardSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section ref={ref} className="py-20 bg-gradient-to-r from-indigo-800 to-purple-800">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { y: 50, opacity: 0 },
            visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
          }}
        >
          Create Your Own Flashcards
        </motion.h2>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-12 md:space-y-0 md:space-x-12">
          <FlashcardSample 
            image="/images/Flashcard02.png" 
            alt="Flashcard: What is the capital of Norway?"
            controls={controls}
            custom={0}
          />
          <FlashcardSample 
            image="/images/Flashcard01.png" 
            alt="Flashcard: What is the 'law of diminishing returns'?"
            controls={controls}
            custom={1}
          />
        </div>
        <motion.div 
          className="text-center mt-16"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { y: 50, opacity: 0 },
            visible: { y: 0, opacity: 1, transition: { duration: 0.8, delay: 0.6 } },
          }}
        >
          <Link to="/login" className="px-8 py-4 rounded-full bg-white text-purple-700 font-bold text-lg hover:bg-purple-100 transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1">
            Try Flashcards Now
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

const FlashcardSample = ({ image, alt, controls, custom }) => (
  <motion.div 
    className="flashcard-sample transform transition duration-300 hover:scale-105"
    initial="hidden"
    animate={controls}
    variants={{
      hidden: { y: 50, opacity: 0 },
      visible: (i) => ({ y: 0, opacity: 1, transition: { duration: 0.8, delay: i * 0.3 } }),
    }}
    custom={custom}
  >
    <img src={image} alt={alt} className="rounded-lg shadow-2xl" />
  </motion.div>
);

const QuizSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section ref={ref} className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16 text-indigo-900"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { y: 50, opacity: 0 },
            visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
          }}
        >
          Challenge Yourself with Quizzes
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <motion.div 
            className="w-full md:w-1/2 mb-8 md:mb-0"
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { y: 50, opacity: 0 },
              visible: { y: 0, opacity: 1, transition: { duration: 0.8, delay: 0.2 } },
            }}
          >
            <img src="/images/quiz.png" alt="Sample Quiz Question" className="rounded-lg shadow-lg" />
          </motion.div>
          <motion.div
            className="w-full md:w-1/2 md:pl-12"
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { y: 50, opacity: 0 },
              visible: { y: 0, opacity: 1, transition: { duration: 0.8, delay: 0.4 } },
            }}
          >
            <h3 className="text-2xl font-bold mb-4 text-indigo-800">AI-Powered Quiz Generation</h3>
            <ul className="space-y-4 text-lg text-gray-700">
              <li>
                <span className="font-semibold">Customized Questions:</span> Our AI generates quizzes tailored to your selected category and difficulty level.
              </li>
              <li>
                <span className="font-semibold">Adaptive Learning:</span> Questions are created from your flashcards and new content, enhancing your knowledge.
              </li>
              <li>
                <span className="font-semibold">Difficulty Levels:</span> Choose from easy, medium, or hard questions to match your learning pace.
              </li>
              <li>
                <span className="font-semibold">Instant Feedback:</span> Get immediate results and explanations to boost your understanding.
              </li>
            </ul>
          </motion.div>
        </div>
        <motion.div 
          className="text-center mt-16"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { y: 50, opacity: 0 },
            visible: { y: 0, opacity: 1, transition: { duration: 0.8, delay: 0.6 } },
          }}
        >
          <Link to="/login" className="px-8 py-4 rounded-full bg-green-600 text-white font-bold text-lg hover:bg-green-700 transition duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1">
            Start a Quiz
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

const FeaturesSection = () => (
  <section id="features" className="py-12 sm:py-20">
    <div className="container mx-auto px-4 sm:px-6">
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Why FlashMaster?</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
        <FeatureCard
          title="Custom Flashcard Creation"
          description="Create flashcards manually or let AI do the heavy lifting. Tailor each flashcard to your specific study needs."
        />
        <FeatureCard
          title="Study Your Way"
          description="Stay motivated with flexible study modes and test your knowledge with quizzes."
        />
        <FeatureCard
          title="Organize & Manage"
          description="Easily organize, edit, and manage your flashcards in different categories."
        />
        <FeatureCard
          title="Progress Tracking"
          description="Stay on top of your learning with in-depth analytics and progress tracking."
        />
      </div>
    </div>
  </section>
);

const FeatureCard = ({ title, description }) => (
  <div className="feature-card bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
    <h3 className="text-xl font-semibold mb-4">{title}</h3>
    <p>{description}</p>
  </div>
);

const HowItWorksSection = () => (
  <section className="gradient-bg text-white py-12 sm:py-20">
    <div className="container mx-auto px-4 sm:px-6">
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">How It Works</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
        <StepCard number={1} title="Sign Up and Log In" description="Get started by creating an account or logging in." />
        <StepCard number={2} title="Create or Generate Flashcards" description="Add your own custom flashcards or let our AI generate them for you." />
        <StepCard number={3} title="Study and Track Progress" description="Use Study Mode or Quiz Mode and track your mastery." />
        <StepCard number={4} title="Manage and Organize" description="Edit flashcards and keep your study sessions organized." />
      </div>
    </div>
  </section>
);

const StepCard = ({ number, title, description }) => (
  <div className="text-center">
    <div className="bg-white text-blue-600 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4 text-2xl font-bold">{number}</div>
    <h3 className="font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const Footer = () => (
  <footer className="bg-indigo-900 text-white py-12">
    <div className="container mx-auto px-4 text-center">
      <p>&copy; 2024 FlashMaster. All rights reserved.</p>
    </div>
  </footer>
);

export default LandingPage;
