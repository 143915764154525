import React, { useState } from 'react';
import { PencilIcon, Sparkles, Library, BookOpen, LayoutDashboard, LogOut, HelpCircle, Menu, ChevronLeft } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from "../lib/utils";

const NavItem = ({ icon: Icon, label, isActive, onClick }) => (
  <Button
    variant={isActive ? 'secondary' : 'ghost'}
    className="w-full justify-start"
    onClick={onClick}
  >
    <Icon className="mr-2 h-4 w-4" />
    <span className="md:block">{label}</span>
  </Button>
);

const Sidebar = ({ activeTab, setActiveTab, onLogout, isExpanded, toggleSidebar }) => (
  <div className={cn(
    "fixed md:static inset-y-0 left-0 z-30 w-64 bg-white shadow-lg transition-transform duration-300 ease-in-out",
    isExpanded ? "translate-x-0" : "-translate-x-full md:translate-x-0"
  )}>
    <div className="flex flex-col h-full p-4">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <svg className="w-8 h-8 mr-2" viewBox="0 0 100 100">
            <rect x="10" y="20" width="80" height="60" rx="5" ry="5" fill="#ffffff" stroke="#667eea" strokeWidth="4"/>
            <path d="M40,30 L60,50 L40,70 L50,50 Z" fill="#764ba2"/>
          </svg>
          <h1 className="text-2xl font-bold">FlashMaster</h1>
        </div>
        <Button variant="ghost" className="md:hidden" onClick={toggleSidebar}>
          <ChevronLeft className="h-6 w-6" />
        </Button>
      </div>
      <nav className="flex-grow space-y-2">
        <NavItem icon={LayoutDashboard} label="Dashboard" isActive={activeTab === 'dashboard'} onClick={() => setActiveTab('dashboard')} />
        <NavItem icon={PencilIcon} label="Manual Creation" isActive={activeTab === 'manual'} onClick={() => setActiveTab('manual')} />
        <NavItem icon={Sparkles} label="AI Generation" isActive={activeTab === 'ai'} onClick={() => setActiveTab('ai')} />
        <NavItem icon={Library} label="Manage Flashcards" isActive={activeTab === 'manage'} onClick={() => setActiveTab('manage')} />
        <NavItem icon={BookOpen} label="Study" isActive={activeTab === 'study'} onClick={() => setActiveTab('study')} />
        <NavItem icon={HelpCircle} label="Quiz" isActive={activeTab === 'quiz'} onClick={() => setActiveTab('quiz')} />
      </nav>
      <Button variant="outline" className="w-full justify-start mt-auto" onClick={onLogout}>
        <LogOut className="mr-2 h-4 w-4" />
        Log Out
      </Button>
    </div>
  </div>
);

const AppLayout = ({ children, activeTab, setActiveTab, onLogout }) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const toggleSidebar = () => setIsSidebarExpanded(!isSidebarExpanded);

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
        onLogout={onLogout}
        isExpanded={isSidebarExpanded}
        toggleSidebar={toggleSidebar}
      />
      
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white shadow-sm p-4 md:hidden">
          <Button variant="ghost" onClick={toggleSidebar}>
            <Menu className="h-6 w-6" />
          </Button>
        </header>
        
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 py-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;