import React, { useState } from 'react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Checkbox } from "./ui/checkbox";
import { Upload } from 'lucide-react';
import * as XLSX from 'xlsx';

const ExcelUploader = ({ onAddFlashcards }) => {
  const [excelData, setExcelData] = useState([]);
  const [selectedFlashcards, setSelectedFlashcards] = useState([]);
  const [error, setError] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const bstr = event.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: ['Topic', 'Question', 'Answer'] });

      // Remove header row if present
      if (data.length > 0 && data[0].Topic === 'Topic') {
        data.shift();
      }

      setExcelData(data);
      setSelectedFlashcards(new Array(data.length).fill(true));
    };

    reader.readAsBinaryString(file);
  };

  const handleFlashcardSelection = (index) => {
    const newSelectedFlashcards = [...selectedFlashcards];
    newSelectedFlashcards[index] = !newSelectedFlashcards[index];
    setSelectedFlashcards(newSelectedFlashcards);
  };

  const handleAddSelectedFlashcards = () => {
    const flashcardsToAdd = excelData
      .filter((_, index) => selectedFlashcards[index])
      .map(({ Topic, Question, Answer }) => {
        if (!Topic || !Question || !Answer) {
          setError("All fields (Topic, Question, and Answer) must be filled for each flashcard.");
          return null;
        }
        return {
          topic: Topic.trim(),
          question: Question.trim(),
          answer: Answer.trim(),
          category: Topic.trim()
        };
      })
      .filter(flashcard => flashcard !== null);

    if (flashcardsToAdd.length > 0) {
      onAddFlashcards(flashcardsToAdd);
      setExcelData([]);
      setSelectedFlashcards([]);
      setError(null);
    } else {
      setError("No valid flashcards to add. Please check your Excel file and try again.");
    }
  };

  return (
    <div className="space-y-4">
      <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-4">
        <h4 className="text-blue-800 font-semibold mb-2">Excel Format Guide</h4>
        <p className="text-sm text-blue-700 mb-2">Make sure your Excel file follows this format for best results:</p>
        <ul className="list-disc list-inside text-sm text-blue-700 space-y-1">
          <li>First row: Column headers (Topic, Question, Answer)</li>
          <li>Subsequent rows: Your flashcard data</li>
          <li>All fields (Topic, Question, Answer) must be filled</li>
          <li>Save as .xlsx or .xls file format</li>
        </ul>
      </div>
      
      <div className="flex items-center space-x-2">
        <Button
          onClick={() => document.getElementById('file-upload').click()}
          variant="outline"
          className="bg-violet-50 text-violet-700 hover:bg-violet-100"
        >
          Choose File
        </Button>
        <Input
          id="file-upload"
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          className="hidden"
        />
        <span className="text-sm text-gray-500">
          {excelData.length > 0 ? `${excelData.length} flashcards loaded` : "No file chosen"}
        </span>
      </div>

      {error && <p className="text-red-500 text-sm">{error}</p>}
      
      {excelData.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold mb-2">Flashcards from Excel:</h3>
          {excelData.map((flashcard, index) => (
            <div key={index} className="flex items-start space-x-2 p-3 bg-gray-50 rounded-md mt-2">
              <Checkbox
                checked={selectedFlashcards[index]}
                onCheckedChange={() => handleFlashcardSelection(index)}
                className="mt-1"
              />
              <div className="flex-grow">
                <p className="font-medium">Topic: {flashcard.Topic}</p>
                <p>Q: {flashcard.Question}</p>
                <p className="text-gray-600">A: {flashcard.Answer}</p>
              </div>
            </div>
          ))}
          <Button
            onClick={handleAddSelectedFlashcards}
            className="mt-4 w-full bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white font-semibold py-2 px-4 rounded-lg shadow-lg transform transition duration-200 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
          >
            <Upload className="w-5 h-5 mr-2 inline" />
            Add Selected Flashcards
          </Button>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;